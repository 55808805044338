

    .accordion-button {
      /* background-color: gray !important; */
      background-color: #6c757d;
      color: #ffffff;
      border-color: #6c757d;
    }
    
    .accordion-button:focus {
      box-shadow: none;
    }
    
    .accordion-button:not(.collapsed) {
      color: #6c757d;
    }
  

    .mini-toggle-button {
      display: inline-block;
      cursor: pointer;
      /* font-weight: bold; */
      /* text-transform: uppercase; */
      /* padding: 0.375rem 0.75rem; */
      padding: 0.2rem 0.4rem;
      font-size: 0.75rem;
      line-height: 1.5;
      color: #ffffff;
      background-color: #8a8a8b;
      border: 1px solid #8a8a8b;
      border-radius: 0.5rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .mini-toggle-button:hover {
      background-color: #0056b3;
      border-color: #0056b3;
    }
    .mini-toggle-button.active {
      background-color: #28a745;
      border-color: #28a745;
    }
    .mini-toggle-button.active:hover {
      background-color: #218838;
      border-color: #218838;
    }

    .custom-tooltip {
      width: 500px; /* Adjust the width as needed */
    }