
.equal-width {
  width: 20%; /* Adjust this value as needed */
}

.badge-space {
  margin-left: 8px;
}

.bold-title {
  font-weight: bold;
}

.tight-spacing {
  line-height: 1.25; /* Adjust to your preference */
  margin-bottom: 0.5rem; /* Add spacing between lines */
}

.horizontal-border-only th, 
.horizontal-border-only td {
  border-left: none !important;   /* Remove left border */
  border-right: none !important;  /* Remove right border */
}

.horizontal-border-only thead th,
.horizontal-border-only tbody td {
  border-top: 1px solid #dee2e6;  /* Keep top border */
  border-bottom: 1px solid #dee2e6; /* Keep bottom border */
}

/* For WebKit browsers (Chrome, Safari, etc.) */
.custom-scrollbar-col::-webkit-scrollbar {
  width: 12px; /* Width of the scroll bar */
}

.custom-scrollbar-col::-webkit-scrollbar-track {
  background-color: #343a40; /* Dark background to match bg-dark */
}

.custom-scrollbar-col::-webkit-scrollbar-thumb {
  background-color: #6c757d; /* Lighter color for the thumb (scroll handle) */
  border-radius: 10px;
  border: 2px solid #343a40; /* Border to create contrast */
}

/* For Firefox (scrollbar-color and scrollbar-width) */
.custom-scrollbar-col {
  scrollbar-color: #6c757d #343a40; /* thumb color, track color */
  scrollbar-width: thin; /* Thin scrollbar width */
}


/* Custom Scrollbar for specific modals */

/* For WebKit browsers (Chrome, Safari) */
.custom-scrollbar-modal::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar-modal::-webkit-scrollbar-track {
  background: #343a40; /* Dark background for scrollbar track */
}

.custom-scrollbar-modal::-webkit-scrollbar-thumb {
  background-color: #6c757d; /* Light thumb for dark theme */
  border-radius: 10px;
  border: 2px solid #343a40; /* Adds a border for contrast */
}

/* For Firefox */
.custom-scrollbar-modal {
  scrollbar-color: #6c757d #343a40; /* thumb color and track color */
  scrollbar-width: thin; /* Thin scrollbar */
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: #28a745; /* Green */
}

.amber {
  background-color: #ffc107; /* Amber/Yellow */
}

.red {
  background-color: #dc3545; /* Red */
}

.green-amber-circle {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border-radius: 50%; /* Makes it a circle */
  background: linear-gradient(to right, #28a745 50%, #ffc107 50%);
  display: inline-block;
}

.amber-red-circle {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border-radius: 50%; /* Makes it a circle */
  background: linear-gradient(to right, #ffc107 50%, #dc3545 50%);
  display: inline-block;
}